const { forEach } = require("lodash");
var keyboardAccessibility = require('base/components/keyboardAccessibility');

var prevent = false;

$(document).on({
  mouseenter: function(e) {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile && $('.menu-open').length == 0) {
      prevent = true;
      e.preventDefault();
    }
    //do not open menu if it is already opened by keyboard
    if(!isMobile && $('.menu-open').length) {
      return;
    }
    $('.sub-06').removeClass('hidden');
    $('.other-menu').removeClass('hidden');
    var firstLevel;
    var secondLevel;
    var thirdLevel;

    if ($('.breadcrumb-first').length > 0) {
      firstLevel = $('.breadcrumb-first')[0].dataset.parentcategoryselector;
      if ($('.levelSelector-'+ firstLevel + ' .submenu').length > 0) {
        if ($('.breadcrumb-second').length > 0) {
          secondLevel = $('.breadcrumb-second')[0].dataset.parentcategoryselector;
        }
        if ($('.breadcrumb-third').length > 0) {
          thirdLevel = $('.breadcrumb-third')[0].dataset.parentcategoryselector;
        }

        $('.levelSelector-'+ firstLevel).removeClass('closed');
    
        $('.levelSelector-'+ firstLevel + ' .submenu').removeClass('hidden');
        if (thirdLevel) {
          $('.levelSelector-'+ firstLevel + ' .thirdLevelCatSelector-'+thirdLevel).addClass('-active');
        }
      } else {
        $('.menu06-selector').removeClass('closed');
      }
    } else {
      $('.menu06-selector').removeClass('closed');
    }
    
    $('.menu-item-container').addClass('menu-open');
    $('.menu-item-container').addClass('animate');
    $('.js-menu-wrapper')[0].style.height = `48px`;
    $('.js-menu-wrapper')[0].style.overflow = 'visible';

    if(!isMobile && document.querySelector('.pageIncludePrivateSalesParentMenu')) {
      var pageIncludePrivateSalesParentMenu = document.querySelectorAll('.pageIncludePrivateSalesParentMenu');
      pageIncludePrivateSalesParentMenu.forEach((el) => {
        var url = el.dataset.url;
        $.ajax({
          url: url,
          method: "get",
          success: function(data) {
                $(el).replaceWith(data);
          }
        });
      });
    }

    if (prevent) {
      e.preventDefault();
    }
  },
  mouseleave: function(e) {
    $('.submenu').addClass('hidden');
    $('.menu-item-container').removeClass('animate');
    $('.other-menu').addClass('hidden');
    $('.menu1-selector').remove('closed');
    var toClose =$('.mm-menu__primary__start')[0].dataset.toClose;
    $( '.sub-' + toClose).addClass('hidden');
    var menuSelector = '.menu' + toClose + '-selector';
    $(menuSelector).addClass('closed');
    $('.menu-item-container').removeClass('menu-open');
  }
}, ".mm-menu__primary__start");

$(document).on({
  click: function(e) {
    e.currentTarget.querySelector('.image-video-component').classList.add('-focus');
  }
}, ".pd-page__content .circular-banners .experience-component");

$(document).on({
  click: function(e) {
    if (prevent) {
      e.preventDefault();
      prevent = false;
    }
  }
}, ".mm-menu__primary__start");

$(document).on({
  click: function(e) {
    if (this.classList.contains('-unclickable')) {
      e.preventDefault();
      var id = this.id;
      var selector = '.sub-' + id;
      var menuSelectorPrevious = '.menu' + this.parentNode.parentNode.dataset.previousparent + '-selector';
      var menuSelector = '.menu' + id + '-selector';
      var selectorParent = '.sub-' + this.parentNode.parentNode.dataset.previousparent;
      var container = $('.mm-menu__primary__start');
      container[0].dataset.toClose = id;
      $(selectorParent).addClass('hidden');
      $(menuSelectorPrevious).addClass('closed');
      $(menuSelector).removeClass('closed');

      $(selector).removeClass('hidden');
    }
  }
}, ".main-section");

$(document).ready(function() {
  if($('.breadcrumb-item.first-element').length > 0) {
    var first = $('.breadcrumb-item.first-element')[0].children[0].dataset.parentcategory;
    var selector = $('.mm-menu__primary__item');

    for (var i = 0; i < selector.length; i++) {
      if (selector[i].dataset && selector[i].dataset.categoryId == first) {
        selector[i].classList.add('-active');
      }
    }
  }





const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  if (!isMobile) {
    let options = {
      root: document.querySelector('.mm-menu__container'),
      rootMargin: "0px",
      threshold: 1.0,
    };
    
    let observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        var url = entry.target.dataset.url;
        $.ajax({
          url: url,
          method: "get",
          success: function(data) {
             
              var classList = entry.target.classList;
              var elemClass = '';
              classList.forEach((targetClass) => {
                 elemClass = elemClass + '.' + targetClass;
              });
              
              var allElem = document.querySelectorAll(elemClass);
              allElem.forEach((el) => {
                $(el).replaceWith(data);
              });
          }
        });
      });
    }, options);

   

    document.querySelector(".includePrivateHeaderMenuMM") ? observer.observe(document.querySelector(".includePrivateHeaderMenuMM")) : '';
    document.querySelector(".includePrivateHeaderMenuMM6") ? observer.observe(document.querySelector(".includePrivateHeaderMenuMM6")) : ''; 
    document.querySelector(".pageIncludePrivateSalesParentMenuMM") ? observer.observe(document.querySelector(".pageIncludePrivateSalesParentMenuMM")) : '';
    document.querySelector(".pageIncludePrivateSalesParentMenuMM6") ? observer.observe(document.querySelector(".pageIncludePrivateSalesParentMenuMM6")) : '';

  
  }
  //trriger hover event
  $('.visually-hidden-focusable.-navigation-a11y.js-primary-button').on('click', function() {
  // CREATING AN EVENT FOR HOVER
  var event = new MouseEvent('mouseover', {
    'view': window,
    'bubbles': true,
    'cancelable': true
  });

  document.querySelector(".mm-menu__primary__start").dispatchEvent(event);
    $('.brand-item.brand1 a').focus();

    var parentID = $(this).next('div').data('previousparent');
    $('.categories-menu.menu-item:not(.closed)').find('#'+parentID).trigger('click');
  
  }); 


  //click on parent category
  $(".mm-button.-unclickable.js-primary-link.main-section").keydown(function(event) {
    if (event.keyCode === 13) {
        $(this).click();
        $('.categories-menu.menu-item:not(.closed) .mm-button.-unclickable.js-primary-link.main-section.-active').focus();
    }
  });

  //lost focus
  const container = document.querySelector('.mm-menu__primary__start');
  $('.mm-menu__primary').on('keyup', (event) => {
    //keyboard user left menu 
    if (event.keyCode === 9  && !container.contains(document.activeElement)) {
      $('.mm-menu__primary__start').trigger('mouseleave');
    }
  });

  keyboardAccessibility('.mm-menu-item, .mm-button.js-primary-link, .mm-menu__secondary__list__title',
    {
      40: function (menuItem) { // down
        if($(this).hasClass('mm-menu-item') && $(this).hasClass('js-primary-link')) {
          if($(this).parent().next().length > 0) {
            $(this).parent().next().find('a').first().focus();
          } else {
            $('.menu-open ul:not(.d-none) .categories-menu:not(.closed) .parent-menu div:first-child')
            .first()
            .focus();
          }
        
        } else if ($(this).hasClass('main-section') && $(this).hasClass('js-primary-link')) {
          $(this).next().length > 0 ? $(this).next().focus() : $(this).parent().parent().find('.submenu-content').find('.mm-menu-item a').first().focus();
        } else if($(this).hasClass('mm-menu-item')) {
          if($(this).next().length > 0) {
            $(this).next().find('a').focus();
          } else {
            if($(this).parent().parent().next().find('a').first().length > 0) {
              $(this).parent().parent().next().find('a').first().focus();
            } else {
              $(this).focus();
            }
          }
        } else if($(this).parent().next().length > 0) {
          $(this).parent().find('.mm-menu-item a').first().focus();
        }
        
      },
      38: function (menuItem) { // up
        if ($(this).hasClass('main-section') && $(this).hasClass('js-primary-link')) {
          $(this).prev().length > 0 ? $(this).prev().focus() : $(".js-header-menu-goto-brand-mm6").focus();
        } else if($(this).hasClass('mm-menu-item') && !$(this).hasClass('js-header-menu-goto-brand-mm')) {
          if($(this).prev().length > 0) { 
            if($(this).prev().find('a').length > 0) {
              $(this).prev().find('a').focus();
            } 
          } 
          else {
            if($(this).parent().prev().find('a').length > 0) {
              $(this).parent().prev().find('a').last().focus();
            } 
            else {
              $('.menu-open ul:not(.d-none) .categories-menu:not(.closed) .parent-menu div')
              .last()
              .focus();
            }
          }
        } else if($(this).hasClass('mm-menu__secondary__list__title')){ 
          $(this).parent().prev().find('a').last().focus();
        }

      },
      27: function () { // escape
       $('.mm-menu__primary__start').trigger('mouseleave');
      }
  },
  function () {
      if(event.shiftKey && event.keyCode == 9) {
        if($(this).hasClass('js-header-menu-goto-brand-mm')) {
          $('.mm-menu__primary__start').trigger('mouseleave');
        }
        if($(this).prev().length === 0) {
          if($(this).hasClass('js-primary-link') && $(this).hasClass('main-section')) {
            event.preventDefault();
            $(".js-header-menu-goto-brand-mm6").focus();
          }
        }
      } else if (event.keyCode === 9) {
        if($(this).hasClass('js-header-menu-goto-brand-mm6')) {
          event.preventDefault();
          $('.menu-open ul:not(.d-none) .categories-menu:not(.closed) .parent-menu div:first-child')
          .first()
          .focus();
        }
      } else {
        return $(this).parent();
      }    
  }
);
  
});